import React, { useState, useEffect } from 'react';
import imageList from '../utils/ImageData';
// import './ModalGallery.css'; // Import the CSS for animations and responsiveness

const ModalGallery = ({ id }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(id);

    useEffect(() => {
        setCurrentImageIndex(id-1);
    }, [id]);

    const scrollLeft = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : imageList.length - 1
        );
    };

    const scrollRight = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex < imageList.length - 1 ? prevIndex + 1 : 0
        );
    };

    return (
        <div className='relative h-full w-full px-[5%] py-4 md:px-[20%]'>
            <div className='absolute left-[5%] md:left-[20%] top-1/2 transform -translate-y-1/2'>
                <button onClick={scrollLeft} className='bg-gray-200 p-2 rounded'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="h-6 w-6 md:h-10 md:w-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                    </svg>
                </button>
            </div>

            <div className='absolute right-[5%] md:right-[20%] top-1/2 transform -translate-y-1/2'>
                <button onClick={scrollRight} className='bg-gray-200 p-2 rounded'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="h-6 w-6 md:h-10 md:w-10">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
            </div>

            <div className="img-container h-full w-full flex justify-center items-center">
                {imageList.map((src, index) => (
                    <img
                        key={index}
                        src={src}
                        alt={`Gallery image ${index + 1}`}
                        className={`modal-img ${index === currentImageIndex ? 'active' : 'inactive'}`}
                        style={{ display: index === currentImageIndex ? 'block' : 'none' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ModalGallery;

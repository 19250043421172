import React, { useEffect } from 'react';
import AOS from 'aos';
// import "aos/dist/aos.css";
// import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import NotFoundPage from './pages/PageNotFound';
import NavBar from './components/Navbar/NavBar';

function App() {
  // useEffect(() => {
  //   const aos_init = () => {
  //     AOS.init({
  //       once: true,
  //       duration: 1000,
  //       easing: 'ease-out-cubic',
  //     });
  //   }

  //   window.addEventListener('load', () => {
  //     aos_init();
  //   });
  // }, []);

  useDocTitle("Survey Consultant");

  return (
    <>
      <Router>

        <NavBar />

        <ScrollToTop>
          <Routes>
            <Route path="/" element={
              <Home />} />
            <Route path="*" element={
              <NotFoundPage />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;

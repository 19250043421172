import React, { Suspense, lazy } from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Projects from '../components/Projects';
import Services from '../components/Services';
import Contact from '../components/Contact';

const Resources = lazy(() => import('../components/Resources.js'));
const Gallery = lazy(() => import('../components/Gallery.js'));

const Home = () => {
    return (
        <div className=''>
            <Hero />
            <div className='md:px-[15%]'>
                <Intro />
                <Services />
                <Projects />
                {/* <Clients /> */}
                <Cta />
                <Suspense fallback={<div>Loading...</div>}>
                    <Resources />
                    <Gallery />
                </Suspense>
                <Contact />

            </div>

            <Footer />
        </div>

    )
}

export default Home;


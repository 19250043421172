import { useEffect, useRef } from "react";
import "./modalStyles.css";

function Modal({ children, isOpen, handleClose }) {
    const nodeRef = useRef(null);

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    if (!isOpen) return null;

    return (
        <div className="modal" ref={nodeRef} role="dialog" aria-modal="true">
            <button onClick={handleClose} className="text-red-500 bg-white bg-opacity-40 border-2 px-2 border-red-500 hover:font-bold rounded-full close-btn" aria-label="Close modal">
                X
            </button>
            <div className="modal-content">
                {children}
            </div>
        </div>
    );
};

export default Modal;

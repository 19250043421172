

import jouav from '../images/resources/JOUAV.jpg'


import trimble from '../images/resources/trimble ux-5.png'

import phantom from '../images/resources/phantom-4.jpg'

import comnav from '../images/resources/comnav.jpg'

import sokkia from '../images/resources/sokka cx.jpg'

import auto_level_sokkia from '../images/resources/sokkia auto level.png'
import mobile from '../images/resources/mobile.jpg'///sokka cx.jpg'
import datem_summit from '../images/resources/datem summit 7.0.jpg'///sokka cx.jpg'
import agisoft from '../images/resources/agisoft.jpg'///sokka cx.jpg'
import trimble_inpho from '../images/resources/trimble inpho.jpg'///sokka cx.jpg'
import arcgis from '../images/resources/arcgis.jpg'///sokka cx.jpg'
import arcgis_pro from '../images/resources/arcgis pro.jpg'///sokka cx.jpg'
import arcgis_server from '../images/resources/arcgis server.jpg'///sokka cx.jpg'
import autocad from '../images/resources/autocad.jpg'///sokka cx.jpg'
import trimble_terra from '../images/resources/trimble terra.jpg'///sokka cx.jpg'



export const resources_list =
    [
        {
            name: 'DJI Phantom 4 Pro',
            key: 'DJI Phantom 4 Pro',
            
            photo: phantom,
            features: [
                '5-direction obstacle avoidance',
                'Excellent camera',
                'Plentiful flight modes',
                'Long flight time',
                'Outstanding range',
            ]
        },
        {
            name: 'RTK GNSS-ComNav T300 Receiver',
            key: 'RTK GNSS-ComNav T300 Receiver',
            
            photo: comnav,
            features: [
                'Multipurpose Drone',
                'Max flight time approximately 30 minutes',
                'Obstacle Sensing 5-Direction',
                'Satellite Positioning Systems GPS/GLONASS',
                'Maximum angular speed, S-mode: 250°/s A-mode: 150°/s'
            ]
        },
        {
            name: 'Total Station Sokkia CX-101',
            key: 'Total Station Sokkia CX-101',
            
            photo: sokkia,
            features: [
                '1" Accuracy',
                'Fast distance measurement of 0.9s',
                'RED-tech technology reflectorless EDM',
                'Reflectorless operation from 30cm to 500m',
                'Long-range Bluetooth technology',
                'Advanced angle measurement system'
            ]
        }, 
        {
            name: 'Auto Level-SOKKIA',
            key: 'Auto Level-SOKKIA',
            
            photo: sokkia,
        }, {
            name: 'Drone/UAV – Trimble UX 5',
            key: 'Drone/UAV – Trimble UX 5',
            
            photo: trimble,
        }, {
            name: 'Online Communication Device ',
            key: 'Online Communication Device ',
            
            photo: mobile,
        }, {
            name: 'Datem Summit Evolution 7.0',
            key: 'Datem Summit Evolution 7.0',
            
            photo: datem_summit,
        }, {
            name: 'AGIsoft 1.2.5',
            key: 'AGIsoft 1.2.5',
            
            photo: agisoft,
        }, {
            name: 'Trimble inpho UASMaster V-11.0',
            key: 'Trimble inpho UASMaster V-11.0',
            
            photo: trimble_inpho,
        }, {
            name: 'ArcGIS 10.5',
            key: 'ArcGIS 10.5',
            
            photo: arcgis,
        }, {
            name: 'ArcGIS Pro 2.5.0',
            key: 'ArcGIS Pro 2.5.0',
            
            photo: arcgis_pro,
        }, {
            name: 'ArcGIS Server 10.2.2',
            key: 'ArcGIS Server 10.2.2',
            
            photo: arcgis_server,
        }, {
            name: 'AutoCAD 2022',
            key: 'AutoCAD 2022',
            
            photo: autocad,
        }, {
            name: 'Trimble Terra Model 10.6',
            key: 'Trimble Terra Model 10.6',
            
            photo: trimble_terra,
        },
    ]

import img_1 from '../images/projects/1.jpg'
import img_3 from '../images/projects/3.jpg'
import img_5 from '../images/projects/5.jpg'
import img_7 from '../images/projects/7.jpg'
import img_9 from '../images/projects/9.jpg'
import img_10 from '../images/projects/10.jpg'
import img_11 from '../images/projects/11.jpg'
import img_12 from '../images/projects/12.jpg'
import img_13 from '../images/projects/13.jpg'
import img_14 from '../images/projects/14.jpg'
import img_15 from '../images/projects/15.jpg'
import img_16 from '../images/projects/16.jpg'




export const project_list = [
    {
        id: 1,
        name: `Detail Survey for Potential Water Source Selection, Water
Retention and Transmission for Water Supply System at
Sajek of Rangamati District`,
        location: 'Sajek,Rangamati',
        description: null,
        /* imageList: [
           img_9,
            img_10,
            img_11,
            img_12,
            img_13,
           
            img_5,
           
            img_7,
          img_1,
           
            img_3,
            img_14,
            img_15,
            img_16, 
        ] */
    }
    ,
    {
        id: 2,
        name: 'Digital Survey and Master Plan preparation of Pabna Cadet College campus of 30-acre area.',
        location: "Pabna, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:3,
        name: 'LiDAR and RTK Survey in Khulna University 106 acre area.',
        location: "Khulna, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:4,
        name: `LiDAR and RTK Survey in Chittagong University of Engineering and Technology 188 acre area.`,
        location: "Chittagong, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:5,
        name: `Digital Survey and Mauza map digitization in 388-acre land for residential projects of Finiba properties.`,
        location: "Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },
    {
        id:6,
        name: `Digital Survey 11 acre land in Namapara Road Khilkhet, Dhaka.`,
        location: "Dhaka, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:7,
        name: `Digital Survey in 3.31 sq. km area of BTCL Area in Gazipur.`,
        location: "Gazipur, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:8,
        name: `Digital Survey in Mirpur 10 circle for Public Space understanding.`,
        location: "Dhaka, Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },

    {
        id:9,
        name: `RTK GPS-based Topographic and Physical Feature Survey of Satkhira and Sirajgang Municipality (CRISC)`,
        location: "Satkhira-Sirajgang,  Bangladesh",
        description: `Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business. With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business. All data is stored in the cloud and as such it is easily accessible from all devices and from any location.`,
    },
]
import img from '../images/web.svg';
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

import topo from '../images/services/topographic (2).png';
import hydro from '../images/services/hydrographic.png';
import plan from '../images/services/planandengineering.png';
import cadastral from '../images/services/cadastral.png';
import geotech from '../images/services/topographic.png';

import blob1 from '../images/blobs/blob1.png';
import blob2 from '../images/blobs/blob2.png';
import blob3 from '../images/blobs/blob2.png';
import blob4 from '../images/blobs/blob3.png';
import blob5 from '../images/blobs/blob4.png';


const dummy_desc = 'We specialize in creating and optimizing high-quality, custom websites for businesses and organizations of all sizes. Building mobile-friendly and easy-to-use websites and applications for clients. '
export const service_list = [
    {
        name: 'Topographic Survey', blob: blob2, image: topo,
        description: `Aerial Vehicle (UAV) based imaging and mapping is a signature service of Survey Consultant which guarantees precision and detailing of spatial data. Using the most advanced surveying UAV in Bangladesh, Survey Consultant offers clients the most reliable data capturing service for spatial surveying. Since its founding, Survey Consultants has been one of the most trusted names in the industry. Hire us for this service and learn how we cater to the needs of each client, ensuring the results you need and deserve.`
    },
    {
        name: 'Cadastral Survey', blob: blob5, image: cadastral,
        description: `Precision and reliability of data are the fundamentals about those Survey Consultant considers zero tolerance policy when it comes to surveying. Since over a decade of client satisfaction, Survey Consultant is now equipped with the most advanced equipment and data processing systems of spatial and non-spatial surveying which saves a large amount of money, time as well as labor and maintains perfection in data capturing.`
    },

    {
        name: 'Hydrographic Survey', blob: blob4, image: hydro,
        description: `Modern surveying relies as much on software as hardware. Increasingly, with the assistance of more sophisticated collection and storage techniques, the data is gathered under one standard, then extracted and applied to the specific application at hand. Hydrological assessment and Geo-technical investigation are the advanced engineering activities to find the way forward to enhance livability and ensure safe and proper use of our land resource. Survey Consultant is offering these services to be part of never stopping development of mankind.`
    },
    {
        name: 'Planning & Engineering Survey', blob: blob1, image: plan,
        description: `A specialised type of land surveying, an engineering survey provides invaluable insights to any construction project. The geospatial information that these surveys provide can minimise complications and maximise efficiency in a project. The world of engineering is all about precision. Before embarking on an engineering project, it is important to understand all your specifications and to get the right expert advice. Survey Consultant will look at every aspect of your engineering project, and give you the edge when it comes time to do the hard work.`
    },
    {
        name: 'Geotechnical Survey', blob: blob3, image: geotech,
        description: `Geo technical investigation is very important for surface and subsurface exploration, soil and groundwater sampling, and laboratory analysis. By gathering information about the physical characteristics with modern equipment like RTK-GPS, Total Station and many more smart technology, Survey Consultant makes solutions for any foreseeable problems.`
    },

]
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import notfoundimage from '../images/notfound.png'
const PageNotFound = () => {
    return (
        <div className="flex flex-row justify-center items-center mt-40 bg-gray-300 h-[calc(80vh-10rem)]">
            <img
                src={notfoundimage}
                className='absolute md:relative'
                alt="Page Not Found"
            />
            <div className='text-[#696868]  text-center px-2 md:bg-transparent bg-[#e0e0e0] bg-opacity-70  h-full flex flex-col justify-center items-center z-10'>
                <p className='text-[5rem] font-bold font-sans leading-snug'>404</p>
                <p className='text-[1.5rem] capitalize font-semibold'>Page not found</p>
                <div className='py-2 font-semibold opacity-70'>
                    <p >We're sorry, the page you requested could not be found.</p>
                    <p >Please go back to the homepage.</p>
                </div>

                <HashLink to='/' className='px-6 py-2 uppercase text-sm text-white border-transparent hover:border-blue-300 border-2 bg-blue-800 rounded-full font-bold '>go home</HashLink>
            </div>


        </div>
    );
};

export default PageNotFound;
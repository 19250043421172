import img_1 from '../images/projects/1.jpg'
import img_3 from '../images/projects/3.jpg'
import img_5 from '../images/projects/5.jpg'
import img_7 from '../images/projects/7.jpg'
import img_9 from '../images/projects/9.jpg'
import img_10 from '../images/projects/10.jpg'
import img_11 from '../images/projects/11.jpg'
import img_12 from '../images/projects/12.jpg'
import img_13 from '../images/projects/13.jpg'
import img_14 from '../images/projects/14.jpg'
import img_15 from '../images/projects/15.jpg'
import img_16 from '../images/projects/16.jpg'
const imageList= [
        img_9,
        img_10,
        img_11,
        img_12,
        img_13,

        img_5,

        img_7,
        img_1,

        img_3,
        img_14,
        img_15,
        img_16,
    ]
export default imageList
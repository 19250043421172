import React, { useState } from 'react';
import { project_list } from '../utils/projectsdata';
import Modal from './Modal';
import ModalGallery from './ModalGallery';

const ProjectCard = ({ data, handleOpen }) => {
    const { name, description, location } = data;

    return (
        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
            <div className="m-2 text-justify text-sm" onClick={handleOpen}>
                <h4 className="font-semibold my-4 text-lg md:text-xl text-center mb-4">
                    {name}
                </h4>
                <div className="flex justify-center my-4">
                </div>
            </div>
        </div>
    );
}

const Projects = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="my-4 py-20" id="projects">
            <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Projects</h2>
            <div className="flex justify-center">
                <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
            </div>
            <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
                    {project_list.map(item => (
                        <ProjectCard key={item.id} /* handleOpen={() => setIsOpen(item.id)} */ data={item} />
                    ))}
                </div>
            </div>
            <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
                <ModalGallery project_id={isOpen} />
            </Modal>
        </div>
    );
}

export default Projects;
